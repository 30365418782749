import React from "react"
import HeaderImage from "./HeaderImage/headerImage"
import Headline from "./Headline/headline"
import ContentBlocks from "./ContentBlocks/contentBlocks"
import ContactPage from "../../templates/ContactPage/contactPage"

function PageContent(props) {
  const page = props.data.wordpressPage
  const language = props.data.language
  const uri = props.data.uri

  const headerImageData = {
    titles: page.acf.title_overlay,
    subtitles: page.acf.subtitle_overlay,
    image: page.acf.image,
    imageMobile: page.acf.image_mobile,
    language,
    uri,
  }

  const headlineData = {
    titles: page.acf.headline_title,
    titles_hm: page.acf.headline_title_hm,
    subtitles: page.acf.headline_subtitle,
    subtitles_hm: page.acf.headline_subtitle_hm,
    contents: page.acf.headline_content,
    contents_hm: page.acf.headline_content_hm,
    imageObj: page.acf.headline_image,
    imageObj_hm: page.acf.headline_image_hm,
    iconObj: page.acf.icon,
    iconObj_hm: page.acf.headline_icon_hm,
    language,
    uri,
  }
  const hasContentBlock = page.acf.content_block
  const contentBlockData = hasContentBlock
    ? {
        contentBlocks: page.acf.content_block,
        language,
        uri,
      }
    : null

  const isContactPage = props.data.uri === "/contact" ? true : false
  const contactPageData = {
    contactPage: page.acf,
    options: props.data.options,
    language,
  }

  return (
    <div>
      <HeaderImage data={headerImageData} />
      {!isContactPage && <Headline data={headlineData} />}
      {hasContentBlock && <ContentBlocks data={contentBlockData} />}
      {isContactPage && <ContactPage data={contactPageData} />}
    </div>
  )
}

export default PageContent
