import React from "react"
import {
  getTitle,
  getSubtitle,
  getContent,
  getIcon,
  getImage,
  setContentBlocksStyles,
  setBlockStyle,
  splitWords,
} from "../../../../helpers/functions"
import styles from "./blockPage.module.scss"

function BlockPage(props) {
  const { uri, language } = props.data
  const block = props.block

  return (
    <div className={setContentBlocksStyles(uri, styles)}>
      <div
        className={setBlockStyle(block.image, block.icon, styles)}
        key={block.title.title}
      >
        {block.image.add_image &&
          getImage(
            block.image.image,
            styles,
            getTitle(block.title, language),
            block.image.align_image
          )}
        <div className={styles.contentWrapper}>
          <div className={styles.titleIconWrapper}>
            {block.icon.add_icon && block.icon.align_icon === "Top" && (
              <div className={`${styles.iconWrapper} wow fadeIn`}>
                {getIcon(
                  block.icon.icon,
                  styles,
                  "Happy Person Icon",
                  block.icon.align_icon
                )}
              </div>
            )}
            {uri !== "/how-we-help" && (
              <div className={styles.title}>
                <h2 className={"wow fadeIn"}>
                  {splitWords(getTitle(block.title, language))}
                </h2>
              </div>
            )}
          </div>
          {block.subtitle.add_subtitle && (
            <div className={`${styles.subtitle} wow fadeIn`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: getSubtitle(block.subtitle, language),
                }}
              />
            </div>
          )}
          {uri === "/how-we-help" && (
            <div className={styles.title}>
              <h2
                className={"wow fadeIn"}
                dangerouslySetInnerHTML={{
                  __html: getTitle(block.title, language),
                }}
              />
            </div>
          )}
          {block.content.add_content && (
            <div className={`${styles.content} wow fadeIn`}>
              <p
                dangerouslySetInnerHTML={{
                  __html: getContent(block.content, language),
                }}
              />
            </div>
          )}
          {block.icon.add_icon && block.icon.align_icon === "Bottom" && (
            <div className={`${styles.iconWrapper} wow fadeIn`}>
              {getIcon(
                block.icon.icon,
                styles,
                "Happy Person Icon",
                block.icon.align_icon
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BlockPage
