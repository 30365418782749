import React from "react"
import {
  colorWords,
  getTitle,
  getSubtitle,
  getContent,
} from "../../../../helpers/functions"
import styles from "./headlinePage.module.scss"

function HeadlinePage(props) {
  const { contents, titles, subtitles, language, uri } = props.data
  console.log(contents)
  const getPageClass = uri => {
    switch (uri) {
      case "/":
        return `${styles.headline} ${styles.home}`
      case "/who-we-are":
        return `${styles.headline} ${styles.who} ${styles.page}`
      case "/what-we-do":
        return `${styles.headline} ${styles.what} ${styles.page}`
      case "/how-we-help":
        return `${styles.headline} ${styles.how} ${styles.page}`
      case "/contact":
        return `${styles.headline} ${styles.contact} ${styles.page}`
      default:
        return `${styles.headline} ${styles.page}`
    }
  }

  return (
    <div className={getPageClass(uri)}>
      <div className={styles.wrapper}>
        {titles.title && (
          <h1 className={`${styles.title} wow fadeInLeft`}>
            {colorWords(getTitle(titles, language))}
          </h1>
        )}
        <div className={styles.right}>
          <div className={styles.content}>
            {contents.add_content && (
              <p className={"wow fadeInRight"}>
                {subtitles.add_subtitle && (
                  <span
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{
                      __html: getSubtitle(subtitles, language),
                    }}
                  />
                )}
                {contents.content && (
                  <span
                    className={`${styles.content} wow fadeInRight`}
                    dangerouslySetInnerHTML={{
                      __html: getContent(contents, language),
                    }}
                  />
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeadlinePage
