import React from "react"
import Typewriter from "../../../Typewriter/typewriter"
import {
  colorWords,
  getTitle,
  getWordsToAnimate,
  getSubtitle,
  getContent,
  getIcon,
  getImage,
} from "../../../../helpers/functions"
import styles from "./headlineHome.module.scss"

function HeadlineHome(props) {
  const {
    contents,
    titles,
    subtitles,
    imageObj,
    iconObj,
    language,
  } = props.data

  return (
    <div className={`${styles.headline} ${styles.home}`}>
      <div className={`${styles.backgroundImg}`}>
        {imageObj && getImage(imageObj.image, styles, "Background Shape Mint")}
      </div>

      <div className={styles.wrapper}>
        {titles && (
          <h1 className={`${styles.title} wow fadeIn`}>
            <Typewriter
              colorWords={colorWords}
              words={getWordsToAnimate(titles, language)}
              title={getTitle(titles, language)}
            />
          </h1>
        )}
        <div className={styles.right}>
          {iconObj && iconObj.add_icon && (
            <div className={`${styles.iconWrapper} wow fadeIn`}>
              {iconObj && getIcon(iconObj.icon, styles, "Birds")}
            </div>
          )}

          {contents && contents.add_content && (
            <div className={styles.content}>
              <p className={"wow fadeIn"}>
                {subtitles && subtitles.add_subtitle && (
                  <span
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{
                      __html: getSubtitle(subtitles, language),
                    }}
                  />
                )}{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: getContent(contents, language),
                  }}
                />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeadlineHome
