import React from "react"
import styles from "./typewriter.module.scss"

class Typewriter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "",
      currentItemIndex: 0,
    }
    this.type = this.type.bind(this)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.type(this.state.currentItemIndex)
  }

  type(currentItemIndex) {
    this._isMounted &&
      this.setState({ currentItemIndex }, () => {
        this.show(this.props.words[currentItemIndex])
      })
  }

  show(target) {
    let charCount = 0
    this.timer = setInterval(() => {
      this._isMounted &&
        this.setState({ text: target.substr(0, charCount) }, () => {
          if (charCount === target.length) {
            clearInterval(this.timer)
            setTimeout(() => this.hide(target), 1000)
          }
          charCount++
        })
    }, 100)
  }

  hide(target) {
    let charCount = target.length
    this.timer = setInterval(() => {
      this._isMounted &&
        this.setState({ text: target.substr(0, charCount) }, () => {
          if (charCount === 0) {
            clearInterval(this.timer)
            let currentItemIndex = this.state.currentItemIndex + 1
            if (currentItemIndex === this.props.words.length)
              currentItemIndex = 0
            setTimeout(() => this.type(currentItemIndex), 1000)
          }
          charCount--
        })
    }, 80)
  }

  colorWordsTypewriter = string => {
    const colors = [`#fc4a1a`, `#fcbb44`, `#516ff1`]
    let words = string.split(" ")
    words.length = 3
    let i = 0
    words = words.map(word => {
      const color = { color: colors[i] }
      const res = (
        <span style={color} key={word + color}>
          {" "}
          {word === words[1] ? this.state.text : word}
        </span>
      )
      i++
      return res
    })
    return words
  }

  componentWillUnmount() {
    this._isMounted = false
    clearInterval(this.timer)
  }

  render() {
    return (
      <div className={styles.typewriter}>
        <div className={styles.title}>
          {this.colorWordsTypewriter(this.props.title)}
        </div>
      </div>
    )
  }
}

export default Typewriter
