import React from "react"
import {
  colorWords,
  getTitle,
  getSubtitle,
  getContent,
  getIcon,
  getImage,
} from "../../helpers/functions"
import Copyright from "../../components/Footer/Copyright/copyright"
import ContactForm from "./ContactForm/contactForm"
import styles from "./contactPage.module.scss"

function ContactPage(props) {
  const { contactPage, language } = props.data
  const {
    address,
    email,
    telephone,
    contact_icon,
    headline_title,
    headline_subtitle,
    headline_content,
  } = contactPage
  const links = props.data.options.options
    ? props.data.options.options.links
    : null

  return (
    <div className={styles.contactPage}>
      <div>
        <div className={styles.headline}>
          <div className={styles.contentWrapper}>
            {headline_title.title && (
              <h1 className={`${styles.title} wow fadeInLeft`}>
                {colorWords(getTitle(headline_title, language))}
              </h1>
            )}
            {headline_content.add_content && (
              <p className={styles.content}>
                {getContent(headline_content, language)}
              </p>
            )}
          </div>
        </div>
        <div className={styles.contactWrapper}>
          <div className={`${styles.contactForm} wow pulse`}>
            <ContactForm
              language={language}
              title={
                headline_subtitle.add_subtitle &&
                getSubtitle(headline_subtitle, language)
              }
            />
          </div>
          <div className={`${styles.contactDetailsWrapper} wow fadeIn`}>
            <div className={styles.iconWrapper}>
              {contact_icon && getIcon(contact_icon, styles, "Doggy in a cart")}
            </div>
            <div className={styles.contactDetails}>
              {telephone && (
                <p className={styles.telephone}>
                  <a href={`tel:${contactPage.telephone}`}>
                    {contactPage.telephone}
                  </a>
                </p>
              )}
              {email && (
                <p className={styles.email}>
                  <a href={`mailto:${contactPage.email}`}>
                    {contactPage.email}
                  </a>
                </p>
              )}
              {address && (
                <div>
                  <p>
                    {address.house_number} {address.street} {address.town}
                  </p>
                  {address.country && <p>{address.country}</p>}
                </div>
              )}
              <div className={styles.socialLinks}>
                {links &&
                  links.map(link => (
                    <div className={styles.social} key={link.link_text}>
                      <a
                        href={link.link_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getImage(link.icon, styles, link.link_text)}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright data={{ email, telephone }} />
    </div>
  )
}

export default ContactPage
