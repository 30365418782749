import React from "react"
import { Link } from "gatsby"
import {
  getTitle,
  getSubtitle,
  splitSubtitle,
  getImage,
} from "../../../helpers/functions"
import styles from "./headerImage.module.scss"

function HeaderImage(props) {
  const { image, imageMobile, titles, subtitles, language, uri } = props.data
  const headerImageStyles =
    uri === "/"
      ? `${styles.headerImage} ${styles.home}`
      : `${styles.headerImage} ${styles.page}`
  const title = getTitle(titles, language)
  const subtitle = getSubtitle(subtitles, language)

  return (
    <div className={headerImageStyles}>
      <div className={styles.imageDesktop}>
        <div className={styles.overlay}></div>
        {getImage(image, styles, title ? title : "Header Image", null)}
      </div>

      <div className={styles.imageMobile}>
        <div className={styles.overlay}></div>
        {getImage(imageMobile, styles, title ? title : "Header Image", null)}
      </div>
      {uri === "/" && (
        <div className={styles.overlays}>
          {title && (
            <div className={styles.title}>
              <p className={"wow fadeIn"}>{title}</p>
            </div>
          )}
          {subtitle && (
            <div className={`${styles.subtitle} wow fadeIn`}>
              <Link to={"/contact"}>{splitSubtitle(subtitle, styles)}</Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default HeaderImage
