import React from "react"
import {
  getTitle,
  getSubtitle,
  getContent,
  getIcon,
  getImage,
  setContentBlocksStyles,
  setBlockStyle,
  splitWords,
} from "../../../../helpers/functions"
import styles from "./blockCoach.module.scss"

function BlockCoach(props) {
  const { uri, language } = props.data
  const block = props.block

  return (
    <div className={setContentBlocksStyles(uri, styles)}>
      <div
        className={setBlockStyle(block.image, block.icon, styles)}
        key={block.title.title}
      >
        <div className={`${styles.title} ${styles.titleMobile}`}>
          <h2 className={"wow pulse"}>
            {splitWords(getTitle(block.title, language))}
          </h2>
        </div>

        <div className={styles.imageContentWrapper}>
          <div className={`${styles.imageWrapper} wow pulse`}>
            {block.image.add_image &&
              getImage(
                block.image.image,
                styles,
                getTitle(block.title, language),
                block.image.align_image
              )}
          </div>
          <div className={styles.contentWrapper}>
            <div className={`${styles.title} ${styles.titleDesktop}`}>
              <h2 className={"wow pulse"}>
                {splitWords(getTitle(block.title, language))}
              </h2>
            </div>

            {block.subtitle.add_subtitle && (
              <div className={`${styles.subtitle} wow pulse`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: getSubtitle(block.subtitle, language),
                  }}
                />
              </div>
            )}
            {block.content.add_content && (
              <div className={`${styles.content} wow fadeIn`}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getContent(block.content, language),
                  }}
                />
              </div>
            )}
            {block.icon.add_icon && block.icon.align_icon === "Bottom" && (
              <div className={`${styles.iconWrapper} wow pulse`}>
                {getIcon(
                  block.icon.icon,
                  styles,
                  "Executive Coach Icon",
                  block.icon.align_icon
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockCoach
