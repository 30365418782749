import React from "react"
import {
  getTitle,
  getSubtitle,
  getContent,
  getIcon,
  getImage,
  setContentBlocksStyles,
  setBlockStyle,
} from "../../../../helpers/functions"
import styles from "./blockHome.module.scss"

function BlockHome(props) {
  const { uri, language } = props.data
  const block = props.block
  const imgIsRight = block.image.align_image === "Left" ? false : true

  const splitWords = string => {
    let words = string.split(" ")
    words = words.map(word => {
      return <span key={string + word}> {word}</span>
    })
    return words
  }

  return (
    <div className={setContentBlocksStyles(uri, styles)}>
      <div
        className={setBlockStyle(block.image, block.icon, styles)}
        key={block.title.title}
      >
        {block.image && (
          <div className={`${styles.backgroundImg}`}>
            {getImage(
              block.image.image,
              styles,
              getTitle(block.title, language),
              block.image.align_image
            )}
          </div>
        )}
        <div
          className={`${styles.contentWrapper} ${
            imgIsRight ? "wow fadeInLeft" : "wow fadeInRight"
          }`}
        >
          <div className={styles.titleIconWrapper}>
            {block.icon && block.icon.align_icon === "Top" && (
              <div className={styles.iconWrapper}>
                {getIcon(
                  block.icon.icon,
                  styles,
                  "Happy person Icon",
                  block.icon.align_icon
                )}
              </div>
            )}
            {block.title && (
              <div className={styles.title}>
                <h2>{splitWords(getTitle(block.title, language))}</h2>
              </div>
            )}
          </div>
          {block.subtitle && (
            <div className={styles.subtitle}>
              <span
                dangerouslySetInnerHTML={{
                  __html: getSubtitle(block.subtitle, language),
                }}
              />
            </div>
          )}
          {block.content && (
            <div className={styles.content}>
              <p
                dangerouslySetInnerHTML={{
                  __html: getContent(block.content, language),
                }}
              />
            </div>
          )}
          {block.icon && block.icon.align_icon === "Bottom" && (
            <div className={styles.iconWrapper}>
              {getIcon(
                block.icon.icon,
                styles,
                "Happy Person Icon",
                block.icon.align_icon
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BlockHome
