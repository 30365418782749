import React from "react"
import styles from "./contactForm.module.scss"

function ContactForm(props) {
  const language = props.language
  const title = props.title
    ? props.title
    : language === "mt"
    ? "Għid hello!"
    : "Drop us a note!"

  return (
    <div className={styles.contactForm}>
      <form
        className={styles.form}
        method="post" 
        netlify-honeypot="bot-field" 
        data-netlify="true" 
        name="contact"
      >
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />
        
        <h3>{title}</h3>
        <div className={styles.inputWrapper}>
          <input
            aria-label="Name"
            type="text"
            name="name"
            id="name"
            placeholder={language === "mt" ? "Isem" : "Name"}
          />
          <input
            aria-label="Email"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
          />
          <textarea
            aria-label="Message"
            name="message"
            id="message"
            rows="5"
            placeholder={language === "mt" ? "Messaġġ" : "Message"}
          />
          <button type="submit">{language === "mt" ? "Ibgħat" : "Send"}</button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
