import React from "react"
import styles from "./contentBlocks.module.scss"
import BlockHome from "./BlockHome/blockHome"
import BlockPage from "./BlockPage/blockPage"
import BlockCoach from "./BlockCoach/blockCoach"

function ContentBlocks(props) {
  const { contentBlocks, uri } = props.data
  let totalBlocks = contentBlocks.length
  let currentBlock = 0

  const incrementBlockCount = () => {
    currentBlock++
  }

  const isLastBlockofWho = (uri, currentBlock) => {
    if (uri !== "/who-we-are") return false
    return currentBlock === totalBlocks ? true : false
  }

  return (
    <div className={styles.contentBlocks}>
      {contentBlocks.map(block => (
        <React.Fragment key={block.title.title}>
          {incrementBlockCount()}

          {uri === "/" && <BlockHome data={props.data} block={block} />}

          {isLastBlockofWho(uri, currentBlock) && (
            <BlockCoach data={props.data} block={block} />
          )}

          {!isLastBlockofWho(uri, currentBlock) && uri !== "/" && (
            <BlockPage data={props.data} block={block} />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default ContentBlocks
