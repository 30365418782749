import React from "react"
import HeadlineHome from "../Headline/HeadlineHome/headlineHome"
import HeadlinePage from "../Headline/HeadlinePage/headlinePage"
import styles from "./headline.module.scss"

function Headline(props) {
  const { language, uri } = props.data

  const homeData = {
    contents: props.data.contents_hm,
    titles: props.data.titles_hm,
    subtitles: props.data.subtitles_hm,
    imageObj: props.data.imageObj_hm,
    iconObj: props.data.iconObj_hm,
    language,
    uri,
  }
  const pageData = {
    contents: props.data.contents,
    titles: props.data.titles,
    subtitles: props.data.subtitles,
    imageObj: props.data.imageObj,
    iconObj: props.data.iconObj,
    language,
    uri,
  }

  const isHomePage = uri === "/"

  const getPageClass = uri => {
    switch (uri) {
      case "/":
        return `${styles.headline} ${styles.home}`
      case "/who-we-are":
        return `${styles.headline} ${styles.who} ${styles.page}`
      case "/what-we-do":
        return `${styles.headline} ${styles.what} ${styles.page}`
      case "/how-we-help":
        return `${styles.headline} ${styles.how} ${styles.page}`
      case "/contact":
        return `${styles.headline} ${styles.contact} ${styles.page}`
      default:
        return `${styles.headline} ${styles.page}`
    }
  }

  return (
    <div className={getPageClass(uri)}>
      {isHomePage && <HeadlineHome data={homeData} />}
      {!isHomePage && <HeadlinePage data={pageData} />}
    </div>
  )
}

export default Headline
